import React from "react";
import { Layout, Section } from "components";
import map from "../images/sorakcc_map.png";
import noPets from "../images/no-pets.png";
import noRunning from "../images/dont-run.png";
import noSmoking from "../images/no-smoking.png";
import noFood from "../images/no-food.png";
import noTouch from "../images/no-touch.png";
import watchChildren from "../images/watch-children.png";

const IntroPage = () => {
  return (
    <Layout>
      <div className="uk-padding-large"></div>
      <Section
        title="설악산책 소개"
        subtitle="다채로운 문화와 자연의 만남, 복합문화공간 설악산책"
      >
        <div className="uk-grid" uk-scrollspy="cls: uk-animation-fade">
          <div className="uk-width-1-2@s  uk-margin-large-bottom">
            <h3 className="uk-text-bold uk-text-center">지식산책</h3>
            <div className="uk-p uk-text-center">
              도서 1만권 이상의 “북카페 산&책”
            </div>
          </div>

          <div className="uk-width-1-2@s  uk-margin-large-bottom">
            <h3 className="uk-text-bold uk-text-center">미식산책</h3>
            <div className="uk-p uk-text-center">
              맛의 즐거움 “한식당 화반”,“카페 소리”
            </div>
          </div>

          <div className="uk-width-1-2@s  uk-margin-large-bottom">
            <h3 className="uk-text-bold uk-text-center">자연산책</h3>
            <div className="uk-p uk-text-center">
              편안한 휴식과 힐링 “키친 가든”
            </div>
          </div>

          <div className="uk-width-1-2@s  uk-margin-large-bottom">
            <h3 className="uk-text-bold uk-text-center">문화산책</h3>
            <div className="uk-p uk-text-center">
              다채로운 미술작품과 공연 감상
            </div>
          </div>
        </div>
        <div className="uk-p" uk-scrollspy="cls: uk-animation-fade">
          설악산책은 설악케이블카(주)의 법인 설악문화재단이 운영하는
          복합문화공간입니다. 기업의 이익을 문화를 통해 보답하고자 하는
          설악산책은 누구나, 마음껏, 문화예술혜택을 누릴 수 있는 공간이 되는
          것을 목표로 2012 년 9월 개관했습니다.
          <br />
          <br />
          “지역주민과 함께” 설악문화재단이 지키고 키우는 지향입니다.
          설악문화재단은 1971 년부터 50 년 동안 지역의 1500 여명의 인재들에게
          장학금을 지급하였습니다. 2012 년 지역의 문화예술 발전을 위하여
          설악문화센터를 개관하였습니다.
          <br />
          2019년 끊임 없는 소통과 관계 속에 더 나은 내일을 꿈꾸며 지금의
          설악산책으로 자리잡게 되었습니다.
          <br />
          <br />
          설악산책은 개관 후 더 나은 지역문화의 토대를 마련하고자
          노력하였습니다. 작품성을 갖춘 다양한 장르의 무료공연을 개최하였고,
          전시와 교육프로그램 진행뿐 아니라 1만권 이상의 도서를 소장하고 있는
          북카페, 최신방음시설을 겸비한 음악연습실, 세미나실을
          <br />
          지역민에게 무료로 개방하고 있습니다.
          <br />
          <br />
          설악산책은 음악과 지식의 만남, 예술과 지역의 교류에 힘쓰고 있습니다.
          이 공간의 쓰임이 예술문화의 발전에 도움이 될 수 있도록 더 성장할
          것이며, 그 가치를 더해 지역문화의 주축 이 될 수 있도록 역할을 다 할
          것입니다.
        </div>
      </Section>
      <Section title="오시는 길">
        <div className="uk-grid uk-grid-large">
          <div className="uk-width-1-2@s">
            <div
              className="uk-card uk-card-default"
              uk-scrollspy="cls: uk-animation-fade"
            >
              <div className="uk-media-top">
                <a href="https://map.naver.com/v5/entry/place/21799882?c=14308806.2704714,4607380.4220028,15,0,0,0,dh">
                  <img
                    src={map}
                    border="0"
                    alt=""
                    style={{ verticalAlign: "top" }}
                  />
                </a>
              </div>
            </div>
            <div
              className="uk-text-small uk-text-italic uk-text-light uk-text-right"
              uk-scrollspy="cls: uk-animation-fade"
            >
              *지도 클릭시 네이버 지도로 이동합니다.
            </div>
          </div>
          <div className="uk-width-1-2@s ">
            <h4
              className="uk-margin-small-bottom uk-text-bold"
              uk-scrollspy="cls: uk-animation-fade"
            >
              주소
            </h4>
            <div>강원도 속초시 관광로 439 (노학동 980-26)</div>
            <h4
              className="uk-margin-top uk-margin-small-bottom uk-text-bold"
              uk-scrollspy="cls: uk-animation-fade"
            >
              대표번호
            </h4>
            <div uk-scrollspy="cls: uk-animation-fade">033) 638-4002</div>
            <h4
              className="uk-margin-top uk-margin-small-bottom uk-text-bold"
              uk-scrollspy="cls: uk-animation-fade"
            >
              네비게이션
            </h4>
            <div uk-scrollspy="cls: uk-animation-fade">
              화반, 설악산책 명으로 찾기
            </div>
            <h4
              className="uk-margin-top uk-margin-small-bottom uk-text-bold"
              uk-scrollspy="cls: uk-animation-fade"
            >
              고속도로
            </h4>
            <div uk-scrollspy="cls: uk-animation-fade">
              서울양양고속도로, 영동고속도로
            </div>
            <h4
              className="uk-margin-top uk-margin-small-bottom uk-text-bold"
              uk-scrollspy="cls: uk-animation-fade"
            >
              국도 방면
            </h4>
            <div uk-scrollspy="cls: uk-animation-fade">
              서울 &#x2192; 팔당대교 &#x2192; 춘천고속도로 &#x2192; 홍천
              &#x2192; 인제, 원통 &#x2192; 미시령 &#x2192; 한화콘도 4거리 우회전
              &#x2192; 설악산책
            </div>
            <h4
              className="uk-margin-top uk-margin-small-bottom uk-text-bold"
              uk-scrollspy="cls: uk-animation-fade"
            >
              시외버스 이용시
            </h4>
            <div uk-scrollspy="cls: uk-animation-fade">
              시외버스 터미널 도착 후 3번이나 3-1번 파인리조트 콘도 지나 자활촌
              입구 (설악산책) 정류장에서 하차
            </div>
          </div>
        </div>
      </Section>
      <Section
        title="준수사항"
        subtitle="모두가 즐기는 쾌적한 환경을 위하여 다음과 같이 지켜주시기 바랍니다."
      >
        <div className="uk-grid" uk-scrollspy="cls: uk-animation-fade">
          <div className="uk-card uk-width-1-3@s uk-margin-large-bottom">
            <div className="uk-flex uk-flex-column uk-flex-middle">
              <img
                className="uk-card-media-top"
                src={noSmoking}
                width="50%"
                alt=""
              />
              <h4 className="uk-text-center">
                설악산책은 <span className="uk-text-bold">실내&#183;외</span>{" "}
                <span className="uk-text-bold">금연</span>입니다.
              </h4>
            </div>
          </div>

          <div className="uk-card uk-width-1-3@s uk-margin-large-bottom">
            <div className="uk-flex uk-flex-column uk-flex-middle">
              <img
                className="uk-card-media-top"
                src={noPets}
                alt=""
                width="50%"
              />
              <h4 className="uk-text-center">
                공간과 정원의 보호를 위해{" "}
                <span className="uk-text-bold">애완견 출입을 제한</span>합니다.
              </h4>
            </div>
          </div>

          <div className="uk-card uk-width-1-3@s uk-margin-large-bottom">
            <div className="uk-flex uk-flex-column uk-flex-middle">
              <img
                className="uk-card-media-top"
                src={noRunning}
                alt=""
                width="50%"
              />
              <h4 className="uk-text-center">
                <span className="uk-text-bold">뛰거나 소란을</span> 일으키는
                행동은 <span className="uk-text-bold">자제해</span> 주시기
                바랍니다.
              </h4>
            </div>
          </div>

          <div className="uk-card uk-width-1-3@s uk-margin-large-bottom">
            <div className="uk-flex uk-flex-column uk-flex-middle">
              <img
                className="uk-card-media-top"
                src={watchChildren}
                alt=""
                width="50%"
              />
              <h4 className="uk-text-center">
                <span className="uk-text-bold">어린이는</span> 보호자와 함께{" "}
                <span className="uk-text-bold">동행할</span> 수 있도록 협조해
                주시기 바랍니다.
              </h4>
            </div>
          </div>

          <div className="uk-card uk-width-1-3@s uk-margin-large-bottom">
            <div className="uk-flex uk-flex-column uk-flex-middle">
              <img
                className="uk-card-media-top"
                src={noFood}
                alt=""
                width="50%"
              />
              <h4 className="uk-text-center">
                음료를 제외한{" "}
                <span className="uk-text-bold">음식물 섭취는 삼가</span> 해
                주시기 바랍니다.
              </h4>
            </div>
          </div>

          <div className="uk-card uk-width-1-3@s uk-margin-large-bottom">
            <div className="uk-flex uk-flex-column uk-flex-middle">
              <img
                className="uk-card-media-top"
                src={noTouch}
                alt=""
                width="50%"
              />
              <h4 className="uk-text-center">
                전시 작품이나 공공기물 또는 음향 장비{" "}
                <span className="uk-text-bold">
                  파손 시 책임을 물을 수 있습니다.
                </span>
              </h4>
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default IntroPage;
